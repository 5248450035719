:root {
    --blue: #3DA6FC; 
    --yellow: #FDB120; 
    --error: #fc3d3d;
    --dark-blue: #282C34;
    --text-black: #282C34; 
    --text-light: #959595; 
    --bg-main: #ffffff;
    --bg-light-gray: #FCFCFC;
    --bg-light-yellow: rgb(253, 177, 32, 0.05); 
    --bg-light-blue: #F6FBFF; 
    --live: #7FFF00;
}

.center { 
    text-align: center;
}

body { 
    font-family: 'Montserrat', sans-serif;
    background-color: var(--bg-main);
    line-height: 1.5em;
    color: var(--text-black);
}

a.btn.btn-outline {
  color: var(--text-black);
}
a.btn.btn-outline:hover {
  color: white;
}
a:visited {
  color: inherit;
}

.app #main {
  min-height: 70vh;
}

textarea { 
    font-family: 'Montserrat', sans-serif;
}

textarea::placeholder, 
input::placeholder {
    color: var(--text-light);
}

.container {
    max-width: 1350px;
    margin: auto;
    padding: 0 60px;
}

.breakout {
    width: 100vw;
    position: relative;
    left: calc(-1 * (100vw - 100%) / 2);
}

.center { 
    text-align: center;
}

.bold { 
    font-weight: 600;
}

.tag {
    background-color: #F0F0F0;
    padding: 5px 12px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 0.88em;
}

.small-text { 
    font-size: 0.8em;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-top-5 { 
    margin-top: 5px;
}

p, h1, h2, h3 { 
    line-height: 1.75em;
}

h1 {
    font-size: 1.4em;
}

h2 { 
    font-size: 1.3em;
}

h3 {
  margin: 0;
}

.black { 
    color: var(--text-black);
}

.text-light {
    color: var(--text-light);
}

.blue { 
    color: var(--blue);
}

.yellow { 
    color: var(--yellow);
}

.uppercase { 
    text-transform: uppercase;
}

/* BUTTON  */
.btn { 
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    background: none;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 1em;
    cursor: pointer;
}

/* BUTTON OUTLINE  */
.btn.btn-outline.btn-outline-yellow { 
    border: 1px solid var(--yellow);
}

.btn.btn-outline.btn-outline-blue { 
    border: 1px solid var(--blue);
}

.btn-outline-yellow {
    background: linear-gradient(to right, var(--yellow) 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease-in-out;
}

.btn-outline-blue {
    background: linear-gradient(to right, var(--blue) 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease-in-out;
}
  
.btn-outline:hover {
    background-position: left bottom;
    color: white;
}

/* BUTTON FILLED */
.btn-filled-blue {
    border: none;
    background-color: var(--blue);
    color: #ffffff;
}
.btn-filled-black {
    border: none;
    background-color: var(--text-black);
    color: #ffffff;
}
.btn-filled-yellow { 
    border: none;
    background-color: var(--yellow);
    color: #ffffff;
}  

/* PAGE TRANSITIONS */
.fade-enter {
    opacity: 0;
    z-index: 1;
}
  
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 1000ms ease-in;
}

/* CARD */
/* Workshop Card Style */
.card {
  display: block;
  top: 0px;
  position: relative;
  background-color: #fefefe;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  box-shadow: 5px 10px 10px #dedede;
}
.card:hover {
  cursor: pointer;
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}
.card:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
}
.card:hover:before {
  transform: scale(2.15);
}

@media screen and (max-width: 425px) {
    .container {
        padding: 0 30px;
    }
}
#footer { 
    background-color: var(--dark-blue);
    color: white;
    padding: 60px 0 20px 0;
}

#footer .tagline { 
    max-width: 210px;
    text-align: center;
}

#footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

#footer #footer__bottom { 
    text-align: center;
    margin-top: 80px;
}

#footer #footer__bottom a { 
    text-decoration: none;
    color: white;
}

#footer #footer__bottom a:hover { 
    color: var(--yellow);
}

#footer .footer__menu {
    margin: 40px 0;
}

#footer .footer__menu li { 
    margin-top: 12px;
}

#footer .footer__menu li:first-child { 
    margin-top: 0;
}

#footer .footer__menu li a { 
    cursor: pointer;
    text-decoration: none;
    color: white;
}

#footer .footer__menu .footer__menu-heading { 
    font-weight: 700;
}

@media (min-width: 768px) {
    .footer__flex { 
        display: flex;
        flex-wrap: wrap;
    }
    .footer__flex-item { 
        width: 50%;
    }
}

@media (min-width: 1440px) {
    .footer__flex-item { 
        width: 25%
    }
}

@media (max-width: 768px) {
    #footer {
        text-align: center;
    }

    #footer .tagline {
        margin: 0 auto;
    }
}
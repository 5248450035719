#header { 
    box-sizing: border-box;
    width: 100%;
    min-height: 10vh;
    height: auto;
    margin-top: 5vh;
}

#header .small-text.tag { 
    display: inline-block;
}

/* LOGO */
header #logo { 
    display: flex;
    justify-content: center;
    align-items: center;
}

header #logo img {
    width: 150px;
    height: auto;
}

/* MOBILE MENU */
header .mobile-menu { 
    flex: 1;
    text-align: right;
}

header #logo .mobile-menu button {
    border: none;
    background-color: #ffffff;
    text-decoration: none;
    font-size: 1em;
}

/* .NAV */
header .nav { 
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 30px;
}

header .nav .nav__menu .nav__menu-item a.active {
    color: var(--text-black);
}

header .nav.nav-collapsed { 
    display: none;
}
  
header .nav.nav-expanded { 
    overflow: hidden;
    height: auto;
    background: var(--bg-light-gray);
    padding: 20px 0;
    transition: 600ms ease-in-out;
}

header .nav .nav__menu { 
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    width: 100%;
}

header .nav .nav__menu .nav__menu-item { 
    margin-top: 20px;
    text-align: center;
    position: relative;
}

header .nav .nav__menu .nav__menu-item:last-child { 
    margin-right: 0;
}

header .nav .nav__menu .nav__menu-item, 
header .nav .nav__menu .nav__menu-item a { 
    text-decoration: none;
    color: var(--text-light);
}

header .nav .nav__menu .nav__menu-item a:hover { 
    color: var(--dark-blue);
}

/* SUBMENU ICONS */
header .nav .nav__menu .nav__menu-item .nav__menu-arrowDown,
header .nav .nav__menu .nav__menu-item .nav__menu-arrowUp {
    color: var(--blue);
    margin-left: 10px;
    font-size: 0.8em;
}

/* SUB MENU */
header .nav .nav__submenu {
    display: none;
    position: absolute;
    padding: 20px;
    margin: 0;
    left: 0;
    top: 15px;
    width: 290px;
    background: #ffffff;
    box-shadow: 5px 10px 10px #F5F5F5;
    z-index: 99;
    border: solid 2px var(--bg-light-gray);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
}

header .nav .nav__submenu .nav__submenu-item {
    margin: 0;
    margin-bottom: 20px;
}

header .nav .nav__submenu .nav__submenu-item a:hover {
    color: var(--blue);
}


header .nav .nav__submenu .nav__submenu-item.nav__submenu-section {
    color: var(--yellow);
    font-size: 0.8em;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 10px;
}

.nav__menu-arrowUp, 
.nav__menu-arrowDown {
    display: none;
}

/* MEDIA QUERIES */
@media (min-width: 1024px) {
    header .nav.nav-collapsed,
    header .nav.nav-expanded {
        display: inline;
        float: right;
        background: #fff;
    }

    header #logo { 
        display: inline-block;
    }

    header {
        display: flex;
    }
    
    header .mobile-menu {
        display: none;
    }

    header .nav { 
        flex: 1;
        text-align: right;
    }

    header .nav .nav__menu li { 
        display: inline-block;
        margin-right: 40px;
    }

    header .nav .nav__menu .nav__menu-item { 
        margin-top: 0;
        text-align: left;
    }

    header .nav .nav__menu-item:hover .nav__submenu {
        display: flex;
        flex-direction: column;
    }

    header .nav .nav__menu .nav__menu-item .nav__menu-arrowUp,
    header .nav .nav__menu .nav__menu-item a:hover .nav__menu-arrowDown {
        display: none;
    }

    header .nav .nav__menu .nav__menu-item .nav__menu-arrowDown,
    header .nav .nav__menu .nav__menu-item a:hover .nav__menu-arrowUp {
        display: inline;    
    }
}

@media screen and (max-width: 425px) {
    #header { 
        margin-top: 30px;
    }
}

@media screen and (max-width: 768px) {
    header .nav .nav__menu .nav__menu-item a { 
        display: block; 
        width: calc(100% - 10px);
        padding: 12px 5px;
    }
}
#header { 
    box-sizing: border-box;
    width: 100%;
    min-height: 10vh;
    height: auto;
    margin-top: 5vh;
}

#header .small-text.tag { 
    display: inline-block;
}

/* LOGO */
header #logo { 
    display: flex;
    justify-content: center;
    align-items: center;
}

header #logo img {
    width: 150px;
    height: auto;
}

/* MOBILE MENU */
header .mobile-menu { 
    flex: 1 1;
    text-align: right;
}

header #logo .mobile-menu button {
    border: none;
    background-color: #ffffff;
    text-decoration: none;
    font-size: 1em;
}

/* .NAV */
header .nav { 
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 30px;
}

header .nav .nav__menu .nav__menu-item a.active {
    color: var(--text-black);
}

header .nav.nav-collapsed { 
    display: none;
}
  
header .nav.nav-expanded { 
    overflow: hidden;
    height: auto;
    background: var(--bg-light-gray);
    padding: 20px 0;
    transition: 600ms ease-in-out;
}

header .nav .nav__menu { 
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    width: 100%;
}

header .nav .nav__menu .nav__menu-item { 
    margin-top: 20px;
    text-align: center;
    position: relative;
}

header .nav .nav__menu .nav__menu-item:last-child { 
    margin-right: 0;
}

header .nav .nav__menu .nav__menu-item, 
header .nav .nav__menu .nav__menu-item a { 
    text-decoration: none;
    color: var(--text-light);
}

header .nav .nav__menu .nav__menu-item a:hover { 
    color: var(--dark-blue);
}

/* SUBMENU ICONS */
header .nav .nav__menu .nav__menu-item .nav__menu-arrowDown,
header .nav .nav__menu .nav__menu-item .nav__menu-arrowUp {
    color: var(--blue);
    margin-left: 10px;
    font-size: 0.8em;
}

/* SUB MENU */
header .nav .nav__submenu {
    display: none;
    position: absolute;
    padding: 20px;
    margin: 0;
    left: 0;
    top: 15px;
    width: 290px;
    background: #ffffff;
    box-shadow: 5px 10px 10px #F5F5F5;
    z-index: 99;
    border: solid 2px var(--bg-light-gray);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
}

header .nav .nav__submenu .nav__submenu-item {
    margin: 0;
    margin-bottom: 20px;
}

header .nav .nav__submenu .nav__submenu-item a:hover {
    color: var(--blue);
}


header .nav .nav__submenu .nav__submenu-item.nav__submenu-section {
    color: var(--yellow);
    font-size: 0.8em;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 10px;
}

.nav__menu-arrowUp, 
.nav__menu-arrowDown {
    display: none;
}

/* MEDIA QUERIES */
@media (min-width: 1024px) {
    header .nav.nav-collapsed,
    header .nav.nav-expanded {
        display: inline;
        float: right;
        background: #fff;
    }

    header #logo { 
        display: inline-block;
    }

    header {
        display: flex;
    }
    
    header .mobile-menu {
        display: none;
    }

    header .nav { 
        flex: 1 1;
        text-align: right;
    }

    header .nav .nav__menu li { 
        display: inline-block;
        margin-right: 40px;
    }

    header .nav .nav__menu .nav__menu-item { 
        margin-top: 0;
        text-align: left;
    }

    header .nav .nav__menu-item:hover .nav__submenu {
        display: flex;
        flex-direction: column;
    }

    header .nav .nav__menu .nav__menu-item .nav__menu-arrowUp,
    header .nav .nav__menu .nav__menu-item a:hover .nav__menu-arrowDown {
        display: none;
    }

    header .nav .nav__menu .nav__menu-item .nav__menu-arrowDown,
    header .nav .nav__menu .nav__menu-item a:hover .nav__menu-arrowUp {
        display: inline;    
    }
}

@media screen and (max-width: 425px) {
    #header { 
        margin-top: 30px;
    }
}

@media screen and (max-width: 768px) {
    header .nav .nav__menu .nav__menu-item a { 
        display: block; 
        width: calc(100% - 10px);
        padding: 12px 5px;
    }
}
.notFound404 {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notFound404 img { 
    max-width: 100%;
}

.notFound404 a { 
    color: var(--text-black);
    font-weight: 700;
}

.notFound404 a:hover {
    color: var(--yellow);
}
.loading-container {
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loading:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: var(--blue) transparent var(--blue) transparent;
  -webkit-animation: loading 1.2s linear infinite;
          animation: loading 1.2s linear infinite;
}
@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#footer { 
    background-color: var(--dark-blue);
    color: white;
    padding: 60px 0 20px 0;
}

#footer .tagline { 
    max-width: 210px;
    text-align: center;
}

#footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

#footer #footer__bottom { 
    text-align: center;
    margin-top: 80px;
}

#footer #footer__bottom a { 
    text-decoration: none;
    color: white;
}

#footer #footer__bottom a:hover { 
    color: var(--yellow);
}

#footer .footer__menu {
    margin: 40px 0;
}

#footer .footer__menu li { 
    margin-top: 12px;
}

#footer .footer__menu li:first-child { 
    margin-top: 0;
}

#footer .footer__menu li a { 
    cursor: pointer;
    text-decoration: none;
    color: white;
}

#footer .footer__menu .footer__menu-heading { 
    font-weight: 700;
}

@media (min-width: 768px) {
    .footer__flex { 
        display: flex;
        flex-wrap: wrap;
    }
    .footer__flex-item { 
        width: 50%;
    }
}

@media (min-width: 1440px) {
    .footer__flex-item { 
        width: 25%
    }
}

@media (max-width: 768px) {
    #footer {
        text-align: center;
    }

    #footer .tagline {
        margin: 0 auto;
    }
}
:root {
    --blue: #3DA6FC; 
    --yellow: #FDB120; 
    --error: #fc3d3d;
    --dark-blue: #282C34;
    --text-black: #282C34; 
    --text-light: #959595; 
    --bg-main: #ffffff;
    --bg-light-gray: #FCFCFC;
    --bg-light-yellow: rgb(253, 177, 32, 0.05); 
    --bg-light-blue: #F6FBFF; 
    --live: #7FFF00;
}

.center { 
    text-align: center;
}

body { 
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    background-color: var(--bg-main);
    line-height: 1.5em;
    color: #282C34;
    color: var(--text-black);
}

a.btn.btn-outline {
  color: #282C34;
  color: var(--text-black);
}
a.btn.btn-outline:hover {
  color: white;
}
a:visited {
  color: inherit;
}

.app #main {
  min-height: 70vh;
}

textarea { 
    font-family: 'Montserrat', sans-serif;
}

textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
    color: #959595;
    color: var(--text-light);
}

textarea::-ms-input-placeholder, input::-ms-input-placeholder {
    color: #959595;
    color: var(--text-light);
}

textarea::placeholder, 
input::placeholder {
    color: #959595;
    color: var(--text-light);
}

.container {
    max-width: 1350px;
    margin: auto;
    padding: 0 60px;
}

.breakout {
    width: 100vw;
    position: relative;
    left: calc(-1 * (100vw - 100%) / 2);
}

.center { 
    text-align: center;
}

.bold { 
    font-weight: 600;
}

.tag {
    background-color: #F0F0F0;
    padding: 5px 12px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 0.88em;
}

.small-text { 
    font-size: 0.8em;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-top-5 { 
    margin-top: 5px;
}

p, h1, h2, h3 { 
    line-height: 1.75em;
}

h1 {
    font-size: 1.4em;
}

h2 { 
    font-size: 1.3em;
}

h3 {
  margin: 0;
}

.black { 
    color: #282C34; 
    color: var(--text-black);
}

.text-light {
    color: #959595;
    color: var(--text-light);
}

.blue { 
    color: #3DA6FC; 
    color: var(--blue);
}

.yellow { 
    color: #FDB120; 
    color: var(--yellow);
}

.uppercase { 
    text-transform: uppercase;
}

/* BUTTON  */
.btn { 
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    background: none;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 1em;
    cursor: pointer;
}

/* BUTTON OUTLINE  */
.btn.btn-outline.btn-outline-yellow { 
    border: 1px solid #FDB120; 
    border: 1px solid var(--yellow);
}

.btn.btn-outline.btn-outline-blue { 
    border: 1px solid #3DA6FC; 
    border: 1px solid var(--blue);
}

.btn-outline-yellow {
    background: linear-gradient(to right, #FDB120 50%, white 50%);
    background: linear-gradient(to right, var(--yellow) 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease-in-out;
}

.btn-outline-blue {
    background: linear-gradient(to right, #3DA6FC 50%, white 50%);
    background: linear-gradient(to right, var(--blue) 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease-in-out;
}
  
.btn-outline:hover {
    background-position: left bottom;
    color: white;
}

/* BUTTON FILLED */
.btn-filled-blue {
    border: none;
    background-color: #3DA6FC;
    background-color: var(--blue);
    color: #ffffff;
}
.btn-filled-black {
    border: none;
    background-color: #282C34;
    background-color: var(--text-black);
    color: #ffffff;
}
.btn-filled-yellow { 
    border: none;
    background-color: #FDB120;
    background-color: var(--yellow);
    color: #ffffff;
}  

/* PAGE TRANSITIONS */
.fade-enter {
    opacity: 0;
    z-index: 1;
}
  
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 1000ms ease-in;
}

/* CARD */
/* Workshop Card Style */
.card {
  display: block;
  top: 0px;
  position: relative;
  background-color: #fefefe;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  box-shadow: 5px 10px 10px #dedede;
}
.card:hover {
  cursor: pointer;
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}
.card:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
}
.card:hover:before {
  transform: scale(2.15);
}

@media screen and (max-width: 425px) {
    .container {
        padding: 0 30px;
    }
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}


html, body, div, span, applet, object, iframe, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

a {
    text-decoration: none;
}

a, a:active, a:focus {
    outline: 0 !important;
}

input::-moz-focus-inner {
    border: 0;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

button:focus {outline:0;}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


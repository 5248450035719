.notFound404 {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notFound404 img { 
    max-width: 100%;
}

.notFound404 a { 
    color: var(--text-black);
    font-weight: 700;
}

.notFound404 a:hover {
    color: var(--yellow);
}